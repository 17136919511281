<template>
    <v-hover v-slot:default="{hover}">
        <v-card class="card off mx-2 mb-1" :class="{ 'card_hover' : hover}"
                :elevation="hover ? 4 : 2" style="cursor: pointer;">

            <v-card-text class="card-state pa-0 pl-2" :class="cardState">
                <span v-if="false" class="caption red--text">SelectorCard {{ item }}</span>
                <v-row justify="center" align="center" class="" no-gutters>
                    <v-col cols="12">
                        <v-row align="center" no-gutters class="py-1">
                            <v-icon small class="gold--text pr-1">mdi-account-tie</v-icon>
                            <span class="" style="font-size: 1em !important;">{{ item.id }}</span>
                            <span class="font-weight-bold text-uppercase px-2 gold--text" style="font-size: 1em !important;"> {{ item.name }} </span>
                            <span class="" style="font-size: 1em !important;">{{ item.position }}</span>
                        </v-row>
                    </v-col>
                </v-row>
                <v-progress-linear v-if="item.id === leaf"
                                   :active="loading"
                                   :indeterminate="loading"
                                   absolute
                                   bottom
                                   color="gold"
                />
            </v-card-text>
        </v-card>
    </v-hover>
</template>

<script>
export default {
    name: "SelectorCard",
    props: {
        item: Object,
        keys: undefined,
        state: undefined,
        loading: Boolean,
        leaf: String
    },
    data: () => ({
        card: { cols: 6, xs: 6, sm: 3, md: 3, align: 'center', class: 'my-2'}
    }),
    computed: {
        layout() {
            return this.card;
        },

        cardState() {
            if (this.item.disabled) {
                return 'state-grey'
            } else if (!this.item.active) {
                return 'state-red'
            } else {
                return ''
            }
        }
    }
}
</script>